::-webkit-scrollbar{
    height: 8px;
    width: 8px;
    background: gray;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f9f76; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1f9f76; 
}

::-webkit-scrollbar-thumb:horizontal{
    background: #1f9f76;
    border-radius: 10px;
}